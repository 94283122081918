import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

const regexEmail = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;

extend("required", (value) => {
  if (value) {
    return true;
  }
  return "{_field_} không được để trống";
});

extend("min3", (value) => {
  if (value.length >= 3) {
    return true;
  }
  return "{_field_} tối thiểu 3 ký tự";
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Xác nhận mật khẩu không khớp",
});

extend("email", (value) => {
  const check = regexEmail.test(String(value).toLowerCase());
  if (check) {
    return true;
  }
  if (!check) {
    return "{_field_} không hợp lệ";
  }
});
