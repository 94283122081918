import Vue from "vue";
import { AuthService } from "../services/auth.service";
import router from "./";
import $store from "../store";

export async function validateMiddleware(to, from, next) {
  try {
    const isAuthRoute = to.matched.some((item) => item.meta.isAuth);
    if (isAuthRoute) {
      const hasToken = Vue.$cookies.isKey("token");
      const hasUserId = Vue.$cookies.isKey("userId");
      const hasUserType = Vue.$cookies.isKey("userType");
      const hasMerchantId = Vue.$cookies.isKey("merchantId");
      const hasAliasMerchant = Vue.$cookies.isKey("aliasMerchant");
      if (
        hasToken &&
        hasUserId &&
        hasUserType &&
        hasMerchantId &&
        hasAliasMerchant
      ) {
        const token = Vue.$cookies.get("token")
          ? Vue.$cookies.get("token")
          : "";
        const userId = Vue.$cookies.get("userId")
          ? Vue.$cookies.get("userId")
          : "";
        const userType = Vue.$cookies.get("userType")
          ? Vue.$cookies.get("userType")
          : "";
        const merchantId = Vue.$cookies.get("merchantId")
          ? Vue.$cookies.get("merchantId")
          : "";
        const aliasMerchant = Vue.$cookies.get("aliasMerchant")
          ? Vue.$cookies.get("aliasMerchant")
          : "";
        const response = await AuthService.validate(
          token,
          userId,
          userType,
          merchantId,
          aliasMerchant
        );
        if (response.code === "SUCCESS") next();
        else {
          next({ name: "login" });
          Vue.$cookies.keys().forEach((cookie) => Vue.$cookies.remove(cookie));
        }
      } else {
        next({ name: "login" });
      }
    } else next();
  } catch (error) {
    console.log(error);
  }
}

export function checkAppIdMiddleware(to, from, next) {
  const isCheckAppId = to.matched.some((item) => item.meta.checkAppId);
  if (isCheckAppId) {
    const appId = router.history.current.query.appId;
    if (appId || router.history._startLocation.includes("appId")) {
      next();
    } else next({ name: "home" });
  } else next();
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}

export function getLocalDataMiddleware(to, from, next) {
  const currentUserId = $store.state.user.currentUser.userId;
  if (AuthService.hasToken() && !currentUserId) {
    try {
      const token = Vue.$cookies.get("token") ? Vue.$cookies.get("token") : "";
      const userId = Vue.$cookies.get("userId")
        ? Vue.$cookies.get("userId")
        : "";
      const userType = Vue.$cookies.get("userType")
        ? Vue.$cookies.get("userType")
        : "";
      const merchantId = Vue.$cookies.get("merchantId")
        ? Vue.$cookies.get("merchantId")
        : "";
      const aliasMerchant = Vue.$cookies.get("aliasMerchant")
        ? Vue.$cookies.get("aliasMerchant")
        : "";
      $store.commit("user/SET_CURRENT_USER", {
        token,
        userId,
        userType,
        merchantId,
        aliasMerchant,
      });
      next();
    } catch (e) {
      console.log(e);
    }
  } else {
    next();
  }
}

export function checkAccessMiddleware(to, from, next) {
  const currentQueries = window.location.href.includes("?")
    ? window.location.href.substring(window.location.href.indexOf("?"))
    : "";
  const currentUserId = $store.state.user.currentUser.userId;
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);

  // if private route and has logged in
  if (isAuthRoute && currentUserId) return next();
  // if has logged in and go to public route
  if (
    currentUserId &&
    (to.name === "login" ||
      to.name === "login-by-enterprise" ||
      to.name === "login-by-staff")
  )
    next({ path: `/home${currentQueries}` });

  // if (isAuthRoute) return next({ name: "login" });

  const whiteList = [
    "/account",
    "/update-account",
    "/delete-account",
    "/change-password",
  ];

  if (isAuthRoute) {
    if (currentQueries) {
      if (whiteList.includes(to.path)) {
        return next({ path: `/login${currentQueries}&redirect=${to.path}` });
      } else {
        return next({ path: `/login${currentQueries}` });
      }
    } else {
      if (whiteList.includes(to.path)) {
        return next({ path: `/login?redirect=${to.path}` });
      } else {
        return next({ path: "/login" });
      }
    }
  }
  next();
}
