import { DOMAIN_TITLE } from "../.env";

export const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Login.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/login-by-enterprise",
    name: "login-by-enterprise",
    component: () => import("../views/login/LoginByEnterprise.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/login-by-staff",
    name: "login-by-staff",
    component: () => import("../views/login/LoginByStaff.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/login-by-staff/:alias",
    name: "login-by-staff-next",
    component: () => import("../views/login/LoginByStaffNext.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/login-by-3rd-party/set-cache",
    name: "set-cache-for-3rd-party",
    component: () => import("../views/login/SetCacheForThirdParty.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/s/:alias",
    name: "login-by-alias-domain",
    component: () => import("../views/login/LoginByAliasDomain.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/home/:alias",
    name: "home-for-alias-domain",
    component: () => import("../views/HomeForAliasDomain.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/authorization/:alias",
    name: "authorization",
    component: () => import("../views/login/Authorization.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/login/Logout.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/signup/Signup.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/signup-mail/:userId",
    name: "signup-mail",
    component: () => import("../views/signup/Signup-mail.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/user/verify",
    name: "verify",
    component: () => import("../views/signup/Verify.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/signup-success",
    name: "signup-success",
    component: () => import("../views/signup/SignupSuccess.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/create-enterprise",
    name: "create-enterprise",
    component: () => import("../views/createMerchant/CreateEnterprise.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/select-enterprise",
    name: "select-enterprise",
    component: () => import("../views/createMerchant/SelectEnterprise.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true, checkAppId: true },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/forgetPassword/ForgetPassword.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/forget-password-mail",
    name: "forget-password-mail",
    component: () => import("../views/forgetPassword/ForgetPassword-mail.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/forgetPassword/ResetPassword.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/reset-password-success",
    name: "reset-password-success",
    component: () => import("../views/forgetPassword/ResetPasswordSuccess.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/user-inactive",
    name: "user-inactive",
    component: () => import("../views/login/UserInactive.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/merchant-inactive",
    name: "merchant-inactive",
    component: () => import("../views/login/MerchantInactive.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/invalid-or-expired",
    name: "invalid-or-expired",
    component: () => import("../views/signup/InvalidOrExpired.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/email-already-verified",
    name: "email-already-verified",
    component: () => import("../views/signup/EmailAlreadyVerified.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/user-need-verify/:userId",
    name: "user-need-verify",
    component: () => import("../views/login/UserNeedVerify.vue"),
    meta: { title: `${DOMAIN_TITLE}` },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/InfoAccount.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/update-account",
    name: "update-account",
    component: () => import("../views/account/UpdateAccount.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/delete-account",
    name: "delete-account",
    component: () => import("../views/account/DeleteAccount.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/account/ChangePassword.vue"),
    meta: { title: `${DOMAIN_TITLE}`, isAuth: true },
  },
  {
    path: "*",
    redirect: {
      name: "login",
    },
  },
];
