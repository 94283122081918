export default {
  SET_CURRENT_USER(state, currentUserData) {
    state.currentUser = currentUserData;
  },

  CLEAR_DATA(state) {
    state.currentUser = {
      userId: "",
      userType: "",
      merchantId: "",
      aliasMerchant: "",
    };
  },
};
