import state from "./userState";
import mutations from "./userMutations";
import getters from "./userGetters";
import actions from "./userActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
