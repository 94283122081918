import Vue from "vue";
import axios from "axios";
import $store from "../store";
import { API_URL } from "../.env";
import VueCookies from "vue-cookies";
export class AuthService {
  static async login(username, password, aliasMerchant) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/login`,
        data: {
          username,
          password,
          aliasMerchant,
        },
      });
      _setAuthData({
        accessToken: response.data.data.token,
        userId: response.data.data.userId,
        userType: response.data.data.userType,
        merchantId: response.data.data.merchantId,
        aliasMerchant: response.data.data.aliasMerchant,
        userData: response.data.data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async validate(token, userId, userType, merchantId, aliasMerchant) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/validate`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          userId,
          userType,
          merchantId,
          aliasMerchant,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async authorize(token, merchantId, appId, redirectUri) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/authorization`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          merchantId,
          appId,
          redirectUri,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async verify(key) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}/user/verify`,
        params: {
          key,
        },
      });
      _setAuthData({
        accessToken: response.data.data.token,
        userId: response.data.data.userId,
        userType: response.data.data.userType,
        merchantId: response.data.data.merchantId,
        aliasMerchant: response.data.data.aliasMerchant,
        userData: response.data.data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static hasToken() {
    return Boolean(getCookie("token"));
  }
  static resetAuthData() {
    VueCookies.keys().forEach((cookie) => VueCookies.remove(cookie));
  }
}

function _setAuthData({
  accessToken,
  userId,
  userType,
  merchantId,
  aliasMerchant,
  userData,
} = {}) {
  setCookie("token", accessToken);
  setCookie("userId", userId);
  setCookie("userType", userType);
  setCookie("merchantId", merchantId);
  setCookie("aliasMerchant", aliasMerchant);
  $store.commit("user/SET_CURRENT_USER", userData);
  // localStorage.setItem("userData", JSON.stringify(userData));
}

function setCookie(key, value) {
  Vue.$cookies.set(key, value);
}

function getCookie(key) {
  return Vue.$cookies.isKey(key) ? Vue.$cookies.get(key) : null;
}

// function removeCookie(key, domain) {
//   return Vue.$cookies.remove(key, null, domain);
// }
