import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { AuthService } from "../services/auth.service";

import {
  getLocalDataMiddleware,
  checkAccessMiddleware,
  validateMiddleware,
  checkAppIdMiddleware,
  setPageTitleMiddleware,
} from "./middlewares";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//Begin: Check Login Portal 360 Nextshop van hanh
if (location.pathname === "/login-by-staff") {
  AuthService.resetAuthData();
}
//End: Check Login Portal 360 Nextshop van hanh

router.beforeEach(getLocalDataMiddleware);
router.beforeEach(checkAccessMiddleware);
router.beforeEach(validateMiddleware);
router.beforeEach(checkAppIdMiddleware);
router.beforeEach(setPageTitleMiddleware);

/* Vue Router is not meant to handle absolute urls. */
/* So whenever we want to deal with those, we can use this.$router.absUrl(url) */
VueRouter.prototype.absUrl = function (url, newTab = true) {
  const link = document.createElement("a");
  link.href = url;
  link.target = newTab ? "_blank" : "";
  if (newTab) link.rel = "noopener noreferrer"; // IMPORTANT to add this
  link.click();
};

VueRouter.prototype.absUrlCurrentTab = function (url, newTab = false) {
  const link = document.createElement("a");
  link.href = url;
  link.target = newTab ? "_blank" : "";
  if (newTab) link.rel = "noopener noreferrer"; // IMPORTANT to add this
  link.click();
};

export default router;
