import Vue from "vue";
// import App from "./App.vue";
import App from "./layout/index.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import "./vue-validators";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Select2 from "v-select2-component";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import VueCookies from "vue-cookies";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueCookies);
Vue.$cookies.config("6d");

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("Select2", Select2);
Vue.component("Loading", VueLoading);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
